<template>
  <v-container fill-height fluid>
    <v-row align="start" justify="space-around">
      <v-col cols="12" sm="12" md="4">
        <v-row>
          <v-col align="center">
            <h2>931</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-file-input accept=".txt" v-model="form.sueldo" label="Archivo Sueldo" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-file-input accept=".txt" v-model="form.aguinaldo" label="Archivo Aguinaldo" />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field label="Límite" v-model="form.tope" type="number" placeholder="Ingrese un número" outlined />
          </v-col>
        </v-row>

        <v-row>
          <v-col align="center">
            <v-btn elevation="2" color="primary" :disabled="loading" :loading="loading" @click="upload">Aceptar</v-btn>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data() {
      return {
        loading: false,
        form: {
          sueldo: undefined,
          aguinaldo: undefined,
          tope: 0
        }       
      }
    },

    methods: {
      async upload() {
        if (!this.form.sueldo) return this.$toast.push("info", "Por favor seleccione un archivo de sueldo");
        this.loading = true;
        await this.$api.sendForm("931", this.form);
        this.loading = false;
      }
    }
  }
</script>